export function launchXsolla(token: string) {
  // Remove existing modal if any
  const existingModal = document.getElementById('xsolla-modal');

  if (existingModal) {
    existingModal.remove();
  }

  // Create a modal overlay
  const modalOverlay = document.createElement('div');
  modalOverlay.id = 'xsolla-modal';
  modalOverlay.style.position = 'fixed';
  modalOverlay.style.top = '0';
  modalOverlay.style.left = '0';
  modalOverlay.style.width = '100%';
  modalOverlay.style.height = '100%';
  modalOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'; // Semi-transparent background
  modalOverlay.style.display = 'flex';
  modalOverlay.style.alignItems = 'center';
  modalOverlay.style.justifyContent = 'center';
  modalOverlay.style.zIndex = '1000'; // Ensure it appears above other elements
  // Create the iframe for Xsolla PayStation
  const iframe = document.createElement('iframe');
  iframe.id = 'xsolla-paystation-iframe';
  iframe.src = 'https://sandbox-secure.xsolla.com/paystation4/?token=' + token;
  iframe.width = '800';

  if (window.innerWidth < 800) {
    iframe.style.height = '100vh';
  } else {
    iframe.height = '800';
  }

  iframe.allow = 'clipboard-read; clipboard-write; payment';
  iframe.style.border = 'none';
  iframe.style.borderRadius = '10px'; // Optional: Add rounded corners
  iframe.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)'; // Optional: Add shadow effect
  // Append the iframe to the modal overlay
  modalOverlay.appendChild(iframe);
  // Append the modal overlay to the body
  document.body.appendChild(modalOverlay);
  // Optional: Close the modal when clicking outside the iframe

  function broadcast(isSuccess: boolean) {
    window.dispatchEvent(new Event(isSuccess ? 'XsollaWidgetSuccess' : 'XsollaWidgetClosed'));
  }

  modalOverlay.addEventListener('click', (event) => {
    if (event.target === modalOverlay) {
      modalOverlay.remove();
      broadcast(false);
    }
  });

  // Implement postMessage to receive events from the payment UI
  window.addEventListener('message', (event) => {
    // Ensure the message is from Xsolla
    if (event.origin === 'https://sandbox-secure.xsolla.com') {
      let messageData = event.data;
      console.log('Received message from Xsolla:', messageData);

      if (typeof messageData === 'string') {
        try {
          messageData = JSON.parse(messageData);
        } catch (error) {
          console.error('Failed to parse message from Xsolla:', error);
        }
      }

      if (messageData.status === 'done' || messageData.command === 'return') {
        broadcast(true);
        //messageData.order_id
        // Remove the modal after successful payment
        modalOverlay.remove();
      } else if (messageData.status === 'canceled') {
        broadcast(false);
        modalOverlay.remove();
      } else if (messageData.status === 'error') {
        broadcast(false);
        modalOverlay.remove();
      }
    }
  });
}