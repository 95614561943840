<span *ngIf="loadingProjects">
  <p-progressSpinner styleClass="smallSpinner" strokeWidth="6"></p-progressSpinner>
</span>
<p-dropdown *ngIf="!loadingProjects" panelStyleClass="rounded-dropdown-list" styleClass="rounded-dropdown-primary"
  (onChange)="setter($event)" [disabled]="loadingProjects" [(ngModel)]="selection" [options]="projects">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center">
      <span *ngIf="!gameIconAvailable" class="img-alt md:hidden">{{!appId ? "All Games" : selection?.name?.substring(0,
        3)}}</span>
      <img [ngClass]="{'hidden': !gameIconAvailable}" (load)="gameIconLoad()" *ngIf="selection"
        (error)="gameIconMissing()" [src]="'/assets/images/game-icons/'+images[selection.name]+'.png'"
        class="max-w-3rem" alt="" />
      <span class="ml-3 md:inline-block hidden line-height-6" *ngIf="selection">{{ selection.name }}</span>
    </div>
  </ng-template>
  <ng-template let-project pTemplate="item">
    <!-- <img [src]="'/assets/images/game-icons/'+images[project.name]+'.png'" class="w-2rem" alt="" /> -->
    <span *ngIf="project">{{ project.name }}</span>
  </ng-template>
</p-dropdown>