import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, interval, merge, Observable, of, timer, distinctUntilChanged } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as MarketPlaceActions from '../actions/marketplace.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

import {
  buyOffer,
  buyOfferFailure,
  buyOfferSuccess,
  invalidateCache,
  selectAssetById,
  selectAssetByIdFailure,
  selectAssetByIdSuccess,
} from '../actions/marketplace.actions';
import { InventoryActions } from './../actions/inventory.actions';
import { Action, Store } from '@ngrx/store';
import { ReadyGGApiConfiguration } from '@readygg/ng-api/ready-gg-api-configuration';
import { MarketplaceService, ReadyGGInventoryItemData, ReadyGGVirtualItemData, ReadyGGMarketplaceOfferData, ProjectsService } from '@readygg/ng-api';
import { getAssetObj } from '../../utils/assets';
import { MarketplaceGetOffer$Params } from '@readygg/ng-api/fn/marketplace/marketplace-get-offer';
import { AppState } from '../app.state';
import { UserActions } from '../actions/user.actions';
import { selectBalance } from './../selectors/user.selectors';
import { MessageService } from 'primeng/api';


@Injectable()
export class MarketPlaceEffects {
  private cachedRequests: Map<string, Observable<any>> = new Map();
  private cachedAssetRequest$: Observable<Action> | undefined;

  loadMarketPlaceOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketPlaceActions.loadMarketPlaceOffers),
      switchMap((action) => {
        // Serialize action.params to use as the cache key
        const cacheKey = JSON.stringify(action.params);

        // Check if the request with the same params is already cached
        if (!this.cachedRequests.has(cacheKey)) {
          const request$ = this.marketplaceService
            .marketplaceGetOffers(action.params)
            .pipe(
              map(({ virtualItems, inventoryItems, offers }) =>
                MarketPlaceActions.loadMarketPlaceOffersSuccess({
                  assets: (offers || []).map((offer) =>
                    this.mapToAsset(virtualItems, inventoryItems, offer),
                  ),
                }),
              ),
              catchError((error) =>
                of(MarketPlaceActions.loadMarketPlaceOffersFailure({ error })),
              ),
              shareReplay(1), // Cache the response
            );

          // Store the request observable in the cache
          this.cachedRequests.set(cacheKey, request$);
        }

        // Return the cached observable
        return this.cachedRequests.get(cacheKey)!;
      }),
    ),
  );

  buyOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(buyOffer),
      withLatestFrom(this.store.select(selectBalance)),
      mergeMap(([action, balance]) =>
        this.marketplaceService.marketplaceBuyOffer(action.params).pipe(
          mergeMap((response) => [
            buyOfferSuccess({ transactionData: response.transactionData! }),
            invalidateCache(),
            UserActions.updateBalance({ balance: balance - response.transactionData!.deductedCurrencies.quantity! }),
          ]),
          catchError((error) => of(buyOfferFailure({ error }))),
        ),
      ),
    ),
  );

  selectAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectAssetById),
      switchMap((action) => {
        if (!this.cachedAssetRequest$) {
          this.cachedAssetRequest$ = this.marketplaceService
            .marketplaceGetOffer(action.params)
            .pipe(
              mergeMap(({ offer, virtualItems, inventoryItems }) => {
                const asset = this.mapToAsset(virtualItems, inventoryItems, offer);
                return asset.appIds?.length
                  ? this.projectsService.projectsGetById({ body: { projectId: asset.appIds[0] } }).pipe(
                    map(res => ({ ...asset, projectName: res?.project?.name, projectImage: res?.project?.icon })),
                    catchError(() => of(asset)),
                  )
                  : of(asset);
              }),
              map(asset => selectAssetByIdSuccess({ asset })),
              catchError(error => of(selectAssetByIdFailure({ error }))),
              shareReplay(1),
            );
        }

        return this.cachedAssetRequest$;
      }),
    ),
  );

  fetchAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketPlaceActions.fetchAssetById),
      switchMap((action) =>
        this.marketplaceService
          .marketplaceGetOffer(action.params)
          .pipe(
            map(({ offer, virtualItems, inventoryItems }) => {
              return MarketPlaceActions.fetchAssetByIdSuccess({
                asset: this.mapToAsset(virtualItems, inventoryItems, offer),
              });
            }),
            catchError((error) => of(MarketPlaceActions.fetchAssetByIdFailure({ error }))),
          ),
      ),
    ),
  );

  invalidateCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketPlaceActions.invalidateCache),
      tap(() => {
        this.cachedRequests.clear();
      }),
    ),
  { dispatch: false },
  );

  monitorAssetStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MarketPlaceActions.buyOfferSuccess,
        MarketPlaceActions.startMonitoringAsset,
      ), // Start monitoring after a successful purchase or when the startMonitoringAsset action is triggered
      switchMap(({ transactionData }) => {

        const offerId = transactionData.offerId;
        const params: MarketplaceGetOffer$Params = {
          body: {
            offerId,
          },
        };

        const polling$ = interval(5000).pipe(
          debounceTime(1000), // Prevent multiple requests from being sent simultaneously
          distinctUntilChanged(),
          map(() => MarketPlaceActions.fetchAssetById({ params })),
          catchError((error) => {
            console.error('Error polling asset status:', error);
            return EMPTY; // It completes the observable and stop the polling process when an error occurs
          }),
        );

        const timeout$ = timer(60000).pipe(
          map(() => MarketPlaceActions.pollingStoppedTimeout()),
        );

        const confirmed$ = this.actions$.pipe(
          ofType(MarketPlaceActions.fetchAssetByIdSuccess),
          filter((action: any) => action.asset.status === 'confirmed' || action.asset.status === 'marketplace_pending'),
          tap((action: any) => {
            if (action.asset.status === 'marketplace_pending') {
              this.messageService.clear();

              this.messageService.add({
                severity: 'info',
                summary: 'Transaction Pending',
                detail: 'The transaction is still pending, please wait...',
                life: 2000,
              });
            }
          }),
          filter((action: any) => action.asset.status === 'confirmed'),
          mergeMap((action: any) => [
            MarketPlaceActions.pollingStoppedSuccess({ asset: action.asset }),
            invalidateCache(),
            InventoryActions.loadInventoryItems({ params: { body: { includeVirtualItemsData: true } } }),
            MarketPlaceActions.loadMarketPlaceOffers({ params: { body: { limit: 1000 } } }),
          ]),
        );

        return merge(
          polling$.pipe(takeUntil(merge(confirmed$, timeout$))),
          confirmed$,
          timeout$,
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private marketplaceService: MarketplaceService,
    private messageService: MessageService,
    private projectsService: ProjectsService,
    private store: Store<AppState>,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };

    this.marketplaceService = new MarketplaceService(
      config,
      inject(HttpClient),
    );

    this.projectsService = new ProjectsService(config, inject(HttpClient));
  }

  private mapToAsset(
    virtualItems: ReadyGGVirtualItemData[] | undefined,
    inventoryItems: ReadyGGInventoryItemData[] | undefined,
    offer: ReadyGGMarketplaceOfferData | undefined,
  ) {
    return getAssetObj(
      virtualItems,
      inventoryItems,
      offer && {
        appIds: offer.appIds,
        id: offer.id,
        prices: offer.prices || [],
        status: offer.status,
        virtualItemIds: offer.virtualItemIds,
        inventoryItemIds: offer.inventoryItemIds,
        ownerId: offer.ownerId,
      },
    );
  }
}
