import { Component, Input, SimpleChanges } from '@angular/core';
import { StorageService } from '@app/core/services/storage.service';
import { Asset } from '@app/models/asset';

@Component({
  selector: 'app-asset-mini',
  templateUrl: './asset-mini.component.html',
  styleUrls: ['./asset-mini.component.scss'],
})
export class AssetMiniComponent {
  @Input() asset!: Asset;
  visibleProperties = false;
  @Input() appId!: string | null;

  currencyIcon = '';

  showProperties(event: MouseEvent) {
    this.visibleProperties = !this.visibleProperties;
    event.stopPropagation();
  }

  constructor(private storageService: StorageService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['asset']?.currentValue) {
      if (this.asset.prices && this.asset.prices.length) {
        this.currencyIcon = this.storageService.getCurrencyIcon(
          this.asset.prices[0].appIds![0],
        );
      }
    }
  }

  assetClickHandler(id: string) {
    // @ts-ignore
    gtag('event', 'item_click', {
      id,
    });
  }
}
