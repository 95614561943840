import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { forkJoin, switchMap } from 'rxjs';
import { SwiperContainer } from 'swiper/element';
import { ReadyGGUserProfileData } from '@readygg/ng-api';

import { GameReview, Statistics } from '@app/models/game';
import { AuthService } from '@app/core/services/auth.service';
import { ApiService } from '@app/core/services/api.service';

import { ReviewViewPopupComponent } from '@app/shared/components/review-view-popup/review-view-popup.component';
import { SwiperOptions } from 'swiper/types';
import { ReviewEditPopupComponent } from '@app/shared/components/review-edit-popup/review-edit-popup.component';
import {
  dAppPlatformsToIcons,
  socialNetworksToIcons,
} from '@app/utils/mappers';
import { DApp, DAppDeveloper, Icon } from '@app/models/api';

interface RatingBar {
  text: string;
  width: string;
}

interface PlatformGallery {
  icon: string;
  title: string;
  images: string[];
}

const altAppsLimit = 8;

@Component({
  selector: 'app-dapp',
  templateUrl: './dapp.component.html',
})
export class DappComponent implements OnInit {
  private readonly apiService = inject(ApiService);
  private readonly authService = inject(AuthService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  readonly dialog = inject(Dialog);
  private appId!: string;

  app!: DApp;
  comments: GameReview[] = [];
  stats: Statistics | null = null;
  gallery: PlatformGallery[] = [];
  user: ReadyGGUserProfileData | null = null;
  myReview: GameReview | null = null;
  ratingBars: RatingBar[] = [];
  altApps: DApp[] = [];
  reviews: GameReview[] = [];
  developer: DAppDeveloper | null = null;
  selectedPlatformIdx = 0;
  gamePlatforms: Icon[] = [];
  socialNetworks: Icon[] = [];

  @ViewChild('reviewsSwiper')
    reviewsSwiperContainer!: ElementRef<SwiperContainer>;

  @ViewChild('gallerySwiper')
    gallerySwiperContainer!: ElementRef<SwiperContainer>;

  gallerySwiperConfig: SwiperOptions = {
    spaceBetween: 10,
    navigation: true,
    slidesPerView: 3,
  };

  reviewsSwiperConfig: SwiperOptions = {
    spaceBetween: 10,
    navigation: true,
    slidesPerView: 3,
  };

  constructor() {
    this.route.params
      .pipe(
        takeUntilDestroyed(),
        switchMap((params: Params) => {
          this.appId = params['id'];

          return forkJoin([
            this.apiService.getAppDetails(this.appId),
            this.apiService.getAlternativesForApp(this.appId, altAppsLimit),
            this.apiService.getReviewsForApp(this.appId),
          ]);
        }),
      )
      .subscribe((data) => {
        if (data[0]) {
          this.app = data[0];
          this.gamePlatforms = dAppPlatformsToIcons(this.app.platforms);
          this.socialNetworks = socialNetworksToIcons(this.app.socialUrls);

          this.apiService
            .getDeveloper(this.app.developerId)
            .subscribe((data2) => {
              this.developer = data2;
            });

          // remove platform screenshots if list empty
          this.gallery = (this.app.screenshots || []).reduce((acc, item) => {
            if (item.images.length > 0) {
              let icon = '';
              let title = '';

              switch (item.platformId) {
                case '?1': // TODO not clear what values of platformId
                  icon = 'icon-mobile';
                  title = 'Mobile';
                  break;

                case '?2': // TODO not clear what values of platformId
                  icon = 'icon-pc';
                  title = 'PC';
                  break;

                case '?3': // TODO not clear what values of platformId
                  icon = 'icon-console';
                  title = 'Console';
                  break;
              }

              if (icon && title) {
                acc.push({
                  icon,
                  title,
                  images: item.images.map((img) => img.large),
                });
              }
            }

            return acc;
          }, [] as PlatformGallery[]);

          this.comments = []; // data[0].comments;

          this.stats = {
            //data[0].statistics;
            countRating1: 0,
            countRating2: 0,
            countRating3: 0,
            countRating4: 0,
            countRating5: 0,
            reviewsCount: 0,
            usersCount: 0,
          };

          this.ratingBars = [
            {
              text: '5',
              width:
                this.calcScorePercents(
                  this.stats.countRating5,
                  this.stats.reviewsCount,
                ) + '%',
            },
            {
              text: '4',
              width:
                this.calcScorePercents(
                  this.stats.countRating4,
                  this.stats.reviewsCount,
                ) + '%',
            },
            {
              text: '3',
              width:
                this.calcScorePercents(
                  this.stats.countRating3,
                  this.stats.reviewsCount,
                ) + '%',
            },
            {
              text: '2',
              width:
                this.calcScorePercents(
                  this.stats.countRating2,
                  this.stats.reviewsCount,
                ) + '%',
            },
            {
              text: '1',
              width:
                this.calcScorePercents(
                  this.stats.countRating1,
                  this.stats.reviewsCount,
                ) + '%',
            },
          ];

          this.altApps = data[1].slice(0, altAppsLimit);
          this.reviews = data[2];
        } else {
          this.router.navigate(['/explore']);
        }
      });

    this.authService.user$.pipe(takeUntilDestroyed()).subscribe((user) => {
      this.user = user;

      // load user's review for this app
      this.apiService.getMyReviewForApp(this.appId).subscribe((data) => {
        this.myReview = data;
      });
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  private calcScorePercents(value: number, total: number) {
    return total > 0 ? (100 * value) / total : 0;
  }

  editMyReview() {
    this.dialog.open(ReviewEditPopupComponent, {
      data: {
        app: this.app,
        review: this.myReview,
      },
    });
  }

  openReview(review: GameReview) {
    this.dialog.open(ReviewViewPopupComponent, { data: { review } });
  }

  prevSlide(el: ElementRef<SwiperContainer>) {
    el?.nativeElement?.swiper.slidePrev();
  }

  nextSlide(el: ElementRef<SwiperContainer>) {
    el?.nativeElement?.swiper.slideNext();
  }
}
