import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserProfile = createSelector(
  selectUserState,
  (state: UserState) => {
    return state.profile;
  },
);

export const selectBalance = createSelector(
  selectUserState,
  (state: UserState) => {
    return state.balance;
  },
);

export const selectUserCurrencies = createSelector(
  selectUserState,
  (state: UserState) => {
    return {
      userCurrencies: state.userCurrencies,
    };
  },
);

export const selectUserLoadingState = createSelector(
  selectUserState,
  (state: UserState) => state.loading,
);

export const selectProjects = createSelector(
  selectUserState,
  (state: UserState) => state.projects,
);

export const selectProjectsLoadingState = createSelector(
  selectUserState,
  (state: UserState) => state.loadingProjects,
);

export const selectAppId = createSelector(
  selectUserState,
  (state: UserState) => state.appId,
);
