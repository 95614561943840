<ng-container *ngIf="app">
  <section class="section section-game-hero">
    <div class="game-hero">
      <div class="game-hero__bg">
        <img [src]="app.banner.large" alt="" />
      </div>

      <div class="game-hero__info">
        <div class="game-hero__info-left">
          <div class="game-hero__icon">
            <img [src]="app.icon.small" alt="" />
            <span>
              <i class="icon-infinity"></i>
            </span>
          </div>
          <div class="game-hero__info-platforms">
            <i
              *ngFor="let platform of gamePlatforms"
              [ngClass]="platform.iconClass"
              [title]="platform.name"
            ></i>
          </div>
        </div>
        <div class="game-hero__info-right">
          <a *ngFor="let item of socialNetworks" [href]="item.link">
            <i [ngClass]="item.iconClass"></i>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-game-info lr-space">
    <div class="game-info__flex">
      <div class="game-info__icon">
        <img [src]="app.icon.small" alt="" />
        <span>
          <i class="icon-infinity"></i>
        </span>
      </div>

      <div class="game-info__main">
        <ul class="game-info__categories">
          <li *ngFor="let cat of app.categories">{{ cat }}</li>
          <!-- cat.title -->
        </ul>

        <h1 class="game-info__title h4">{{ app.title }}</h1>

        <a class="game-info__vendor" href="">{{ developer?.name }}</a>
      </div>
      <ul class="game-info__achievements">
        <li>
          <!-- TODO no field in API response -->
          <div># 1</div>
          <div>in RPG</div>
        </li>
        <li>
          <div>{{ 0 | fixedNumber : 1 }}</div>
          <!-- TODO no field in API response -->
          <div>{{ stats?.reviewsCount || 0 }} reviews</div>
        </li>
        <li>
          <!-- TODO no field in API response -->
          <div>{{ stats?.usersCount || 0 | compactNumber }}</div>
          <div>Saves</div>
        </li>
      </ul>
    </div>

    <div class="game-controls__flex">
      <a href="#" class="button button__filled game-controls__explore">
        <span>Explore</span>

        <i class="icon-arrow-right"></i>
      </a>

      <a href="#" class="button game-controls__favorites">
        <i class="icon-heart"></i>
        <span>Add to Vault</span>
      </a>

      <a href="#" class="game-controls__share">
        <i class="icon-share"></i>
      </a>
    </div>
  </section>

  <section
    class="section section-game-gallery lr-space"
    *ngIf="gallery.length > 0"
  >
    <ul class="game-gallery__nav">
      <li *ngFor="let item of gallery; let idx = index">
        <span [ngClass]="{ active: selectedPlatformIdx === idx }">
          <i [ngClass]="item.icon"></i>
          <span>{{ item.title }}</span>
        </span>
      </li>
    </ul>

    <div class="swiper-container swiper-container-gallery">
      <swiper-container appSwiper #gallerySwiper [config]="gallerySwiperConfig">
        <swiper-slide
          *ngFor="let img of gallery[selectedPlatformIdx].images || []"
        >
          <img [src]="img" alt="" />
        </swiper-slide>
      </swiper-container>

      <div
        class="swiper__prev"
        *ngIf="gallery[selectedPlatformIdx].images.length > 3"
        (click)="prevSlide(gallerySwiperContainer)"
      >
        <i class="icon-chevron-down"></i>
      </div>
      <div
        class="swiper__next"
        *ngIf="gallery[selectedPlatformIdx].images.length > 3"
        (click)="nextSlide(gallerySwiperContainer)"
      >
        <i class="icon-chevron-down"></i>
      </div>
    </div>
  </section>

  <section class="section game-socials lr-space">
    <h2 class="section-title game-socials__title h4">Social media</h2>
    <div class="game-socials__list">
      <a *ngFor="let item of socialNetworks" [href]="item.link">
        <i [ngClass]="item.iconClass"></i>
      </a>
    </div>
  </section>

  <section class="section section-game-about lr-space">
    <h2 class="section-title game-about__main-title h4">About</h2>
    <div class="game-about__flex">
      <div class="game-about__main">
        <div class="game-about__main-text">
          {{ app.description }}
        </div>

        <ul class="game-about__tags">
          <li *ngFor="let tag of app.tags">{{ tag }}</li>
        </ul>
      </div>
      <div class="game-about__vendor">
        <h3 class="game-about__vendor-title h6">
          Developer contact
          <i class="icon-chevron-down"></i>
        </h3>

        <ul class="game-about__vendor-list">
          <li *ngIf="developer?.webSiteUrl">
            <div>
              <i class="icon-web"></i>
              Website
            </div>
            <div>
              <a target="_blank" [href]="developer?.webSiteUrl">{{
                developer?.webSiteUrl || "" | urlDomain
              }}</a>
            </div>
          </li>
          <li *ngIf="developer?.email">
            <div>
              <i class="icon-email"></i>
              Email
            </div>
            <div>
              <a [href]="'mailto:' + developer?.email">{{
                developer?.email
              }}</a>
            </div>
          </li>
          <li *ngIf="developer?.privacyPolicy">
            <div>
              <i class="icon-privacy"></i>
              Privacy Policy
            </div>
            <div>
              <a target="_blank" [href]="developer?.privacyPolicy">{{
                developer?.privacyPolicy
              }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="section lr-space" *ngIf="user && !myReview">
    <div class="section__title">
      <h4>Rate this game</h4>
    </div>

    <div class="rate">
      <div class="rate__title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.55957 12.0001C0.55957 5.68177 5.68128 0.560059 11.9996 0.560059C18.3179 0.560059 23.4396 5.68177 23.4396 12.0001C23.4396 18.3183 18.3179 23.4401 11.9996 23.4401C5.68128 23.4401 0.55957 18.3183 0.55957 12.0001ZM1.99957 12.0001C1.99957 6.47706 6.47657 2.00006 11.9996 2.00006C17.5226 2.00006 21.9996 6.47706 21.9996 12.0001C21.9996 17.5231 17.5226 22.0001 11.9996 22.0001C6.47657 22.0001 1.99957 17.5231 1.99957 12.0001Z"
            fill="url(#paint0_linear_754_6681)"
          />
          <g filter="url(#filter0_i_754_6681)">
            <path
              d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
              fill="url(#paint1_linear_754_6681)"
            />
          </g>
          <g filter="url(#filter1_d_754_6681)">
            <path
              d="M14.75 16.4951C15.164 16.4951 15.5 16.1596 15.5 15.7451V7.75011C15.5 7.33561 15.164 7.00011 14.75 7.00011C14.336 7.00011 14 7.33561 14 7.75011V11.0006L10.0005 11.0096L10 7.74561C10 7.33111 9.6635 6.99561 9.2495 6.99561C8.8355 6.99561 8.5 7.33161 8.5 7.74561L8.5015 15.7481C8.5015 16.1626 8.8375 16.4981 9.2515 16.4981C9.6655 16.4981 10.0015 16.1626 10.0015 15.7481L10.001 12.5091L14 12.5006V15.7451C14 16.1596 14.336 16.4951 14.75 16.4951Z"
              fill="url(#paint2_linear_754_6681)"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_754_6681"
              x="2"
              y="2"
              width="20"
              height="20"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.61074" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_754_6681"
              />
            </filter>
            <filter
              id="filter1_d_754_6681"
              x="7.05034"
              y="5.54594"
              width="9.89933"
              height="12.4018"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.724832" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_754_6681"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_754_6681"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_754_6681"
              x1="0.559571"
              y1="2.99106"
              x2="21.3117"
              y2="12.7088"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF61C2" />
              <stop offset="1" stop-color="#945FFF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_754_6681"
              x1="2"
              y1="2.26026"
              x2="12.6799"
              y2="25.8953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#131C4D" />
              <stop offset="1" stop-color="#4A3DAF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_754_6681"
              x1="8.5"
              y1="8.00525"
              x2="15.418"
              y2="10.3916"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF61C2" />
              <stop offset="1" stop-color="#945FFF" />
            </linearGradient>
          </defs>
        </svg>
        <div class="rate__content">
          Write review for this game and receive 25 HALO
          <!--
          <div class="rate__subtitle">Only works for the first review</div>
          -->
        </div>
      </div>

      <button
        class="rate__button button button__filled"
        (click)="editMyReview()"
      >
        Write a review
      </button>
    </div>
  </section>

  <section class="section lr-space" *ngIf="user && myReview">
    <div class="section__title">
      <h4>My review</h4>
    </div>

    <div class="my__reviews">
      <div class="my__reviews-top">
        <div class="my__reviews-flex">
          <div class="my__reviews-left">
            <img [src]="user.profilePicture.source" alt="" />
          </div>
          <div class="my__reviews-right">
            <div class="my__reviews-date">{{ myReview.date | date }}</div>
            <div class="my__reviews-name">{{ user.displayName }}</div>
            <div class="my__reviews-stars">
              <i
                class="icon-star"
                *ngFor="let foo of [].constructor(5); let idx = index"
                [ngClass]="{ marked: idx + 1 <= myReview.rating }"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="my__reviews-full" [innerHTML]="myReview.content"></div>

      <div class="my__reviews-edit" (click)="editMyReview()">Edit review</div>
    </div>
  </section>

  <section class="section section-srore lr-space">
    <div class="section__title">
      <h4>User score & Reviews</h4>

      <a href="#" class="all-link">
        View All
        <i class="icon-arrow-right"></i>
      </a>
    </div>

    <div class="score">
      <div class="score__flex">
        <div class="score__left">
          <div class="score__rate">{{ 0 | fixedNumber : 1 }}</div>
          <!-- TODO no field in API response -->
          <div class="score__stars">
            <i
              class="icon-star"
              *ngFor="let foo of [].constructor(5); let idx = index"
              [ngClass]="{ marked: idx + 1 <= 0 }"
            ></i>
            <!-- TODO no field in API response -->
          </div>
          <div class="score__count">{{ stats?.reviewsCount || 0 }} reviews</div>
        </div>
        <div class="score__right">
          <div class="score__bar" *ngFor="let item of ratingBars">
            <div class="score__bar-count">{{ item.text }}</div>
            <div class="score__bar-fill">
              <span [style.width]="item.width"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-container swiper-container-score">
        <swiper-container
          appSwiper
          #reviewsSwiper
          [config]="reviewsSwiperConfig"
        >
          <swiper-slide *ngFor="let review of reviews">
            <div class="score__item-top">
              <div class="score__item-avatar">
                <img [src]="review.user.avatar" alt="" />
              </div>
              <div class="score__item-content">
                <div class="score__item-date">{{ review.date | date }}</div>
                <div class="score__item-name">{{ review.user.name }}</div>
                <div class="score__item-rate">
                  <i
                    class="icon-star"
                    *ngFor="let foo of [].constructor(5); let idx = index"
                    [ngClass]="{ marked: idx + 1 <= review.rating }"
                  ></i>
                </div>
              </div>
            </div>
            <div class="score__item-middle">
              {{ review.content | ellipsis : 120 }}
            </div>
            <div class="score__item-bottom">
              <span (click)="openReview(review)">Read more</span>
            </div>
          </swiper-slide>
        </swiper-container>

        <div
          class="swiper__prev"
          *ngIf="reviews.length > 3"
          (click)="prevSlide(reviewsSwiperContainer)"
        >
          <i class="icon-chevron-down"></i>
        </div>
        <div
          class="swiper__next"
          *ngIf="reviews.length > 3"
          (click)="nextSlide(reviewsSwiperContainer)"
        >
          <i class="icon-chevron-down"></i>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-games-alt lr-space">
    <div class="section__title">
      <h4>You may also like</h4>
    </div>

    <ul class="games-alt">
      <li class="games-alt__item" *ngFor="let item of altApps">
        <app-dapp-mini-block [app]="item"></app-dapp-mini-block>
      </li>
    </ul>
  </section>
</ng-container>
