import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@NgModule({
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    DialogModule,
    ProgressSpinnerModule,
    MessagesModule,
    DropdownModule,
    AccordionModule,
    MenuModule,
    InputTextModule,
    ToastModule,
  ],
  exports: [
    ButtonModule,
    ConfirmDialogModule,
    DialogModule,
    ProgressSpinnerModule,
    MessagesModule,
    DropdownModule,
    AccordionModule,
    MenuModule,
    InputTextModule,
    ToastModule,
  ],
  declarations: [],
  providers: [MessageService],
})
export class PrimeNgModule {}
