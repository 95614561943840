export const environment = {
  apiUrl: 'https://us-central1-readymaster-development.cloudfunctions.net',
  production: false,
  projectId: 'C7jkuZBXmOhmpYSNjMjd',
  auth: {
    url: 'https://development-oauth.myplay.network',
  },
  gtag_measurement_id: 'G-000',
  forteUrl: 'https://dev-forte-payments-cdn.pti-dev.cloud/forte-payments-widget.js',
  firebaseStorageUrl:
    'https://firebasestorage.googleapis.com/v0/b/readymaster-development.appspot.com/',
};
