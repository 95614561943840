<div class="wrapper">
  <div class="container">
    <p-toast (click)="goToInventory()"></p-toast>
    <div class="wrapper__flex">
      <aside class="sidebar">
        <div class="logo sidebar__logo">

          <div>
            <img src="/assets/images/logo-play.svg" alt="PLAY logo" />
            <p class="mt-1 text-white font-poppins text-sm opacity-80">MARKETPLACE</p>
          </div>

        </div>
        <nav class="navigation">
          <ul class="navigation__list">
            <li class="navigation__item" *ngFor="let item of menu">
              <a class="navigation__link" routerLinkActive="active" [routerLink]="item.link"
                [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{ appId: appId }"
                [ngClass]="{ disabled: item.disabled }">
                <i [ngClass]="item.icon"></i>
                <span>{{ item.title }}</span>
              </a>
            </li>
          </ul>
        </nav>

        <div class="banner">
          <div class="banner__title h4">Test To Earn</div>
          <p class="banner__subtitle font__s">Test games, receive rewards</p>
          <a class="banner__button button button__filled button__s" href="https://t.me/PLAY_GameStore_Ann"
            target="_blank">
            Test Games
          </a>
          <img src="/assets/images/small-banner-1.png" alt="" />
        </div>
      </aside>

      <div class="content">
        <app-header [appId]="appId" [user]="user" [balance]="balance" [currencyIcon]="currencyIcon"></app-header>
        <main class="main">
          <router-outlet></router-outlet>
        </main>
      </div>

      <aside class="mobile-sidebar">
        <a *ngIf="redirect && !fromTelegram" target="_blank" class="backToGame" [href]="returnPath">
          <span class="whitespace-nowrap">
            <i>
              < </i>
                Back to<br />
                Game</span>
        </a>

        <div class="container">
          <ul class="mobile-sidebar__nav mobile-nav">
            <!-- // TODO: hide home & explore for now -->
            <!-- <li class="mobile-nav__item">
              <a
                class="mobile-nav__link"
                [routerLink]="['/explore']"
                routerLinkActive="active"
              >
                <i class="icon-explore-1"></i>
                <span>Explore</span>
              </a>
            </li> -->
            <!-- <li class="mobile-nav__item mobile-nav__home">
              <a
                class="mobile-nav__link _active"
                [routerLink]="['/']"
                routerLinkActive="active"
              >
                <div class="home-svg-wrapper">
                  <i class="icon-home"></i>
                </div>
                <span>Home</span>
              </a>
            </li> -->
            <li class="mobile-nav__item">
              <a class="mobile-nav__link" [routerLink]="['/marketplace']" routerLinkActive="active"
                [queryParamsHandling]="'merge'">
                <i class="icon-cart"></i>
                <span>Marketplace</span>
              </a>
            </li>

            <li class="mobile-nav__item">
              <a class="mobile-nav__link" [routerLink]="['/inventory']" routerLinkActive="active"
                [queryParamsHandling]="'merge'">
                <i class="icon-inventory"></i>
                <span>Inventory</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  </div>
  <img class="wrapper__bg-1" src="/assets/images/bg-0.svg" alt="" />
  <img class="wrapper__bg-2" src="/assets/images/bg-1.svg" alt="" />
</div>