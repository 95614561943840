import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CurrencyService, PaymentsService, ReadyGGApiConfiguration } from '@readygg/ng-api';
import { PaymentsCreateMarketplaceOfferPaymentIntent$Params } from '@readygg/ng-api/fn/payments/payments-create-marketplace-offer-payment-intent';
import { switchMap, tap, map, catchError, of } from 'rxjs';
import * as PaymentActions from '../actions/payment.actions';
import { CurrencyGetRgnCoinEconomy$Params } from '@readygg/ng-api/fn/currency/currency-get-rgn-coin-economy';
import { PaymentsCreateRgnCoinPaymentIntent$Params } from '@readygg/ng-api/fn/payments/payments-create-rgn-coin-payment-intent';
import { launchForte } from '../../utils/forte';
import { launchXsolla } from '../../utils/xsolla';

export enum PROVIDERS {
  xsolla = 'xsolla',
  forte = 'forte',
}
export const ACTIVE_PROVIDER = PROVIDERS.forte;

@Injectable()
export class PaymentEffects {

  constructor(
    private actions$: Actions,
    private paymentService: PaymentsService,
    private currencyService: CurrencyService,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };
    this.paymentService = new PaymentsService(config, inject(HttpClient));
    this.currencyService = new CurrencyService(config, inject(HttpClient));
  }

  fetchCoinBundles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.fetchCoinBundles),
      switchMap((action: { params: CurrencyGetRgnCoinEconomy$Params }) => {

        return this.currencyService.currencyGetRgnCoinEconomy(action.params).pipe(
          map((response) =>
            PaymentActions.fetchCoinBundlesSuccess({
              coinBundles: response.products,
            }),
          ),
          catchError((error) =>
            of(PaymentActions.fetchCoinBundlesFailure({ error })),
          ),
        );
      }),
    ),
  );

  createPaymentIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.createPaymentIntent),
      switchMap((action: { params: PaymentsCreateMarketplaceOfferPaymentIntent$Params }) => {
        const paymentIntent: PaymentsCreateMarketplaceOfferPaymentIntent$Params = action.params;
        return this.paymentService.paymentsCreateMarketplaceOfferPaymentIntent(paymentIntent).pipe(
          tap(({ data }) => {
            if (data) {
              launchForte(data);
            }
          }),
          map((response) =>
            PaymentActions.createPaymentIntentSuccess({ response }),
          ), // Return success action here
          catchError((error) =>
            of(PaymentActions.createPaymentIntentFailure({ error })),
          ), // Return failure action in case of error
        );
      }),
    ),
  );

  createBundlesPaymentIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.createBundlesPaymentIntent),
      switchMap((action: { params: PaymentsCreateRgnCoinPaymentIntent$Params }) => {
        const paymentIntent: PaymentsCreateRgnCoinPaymentIntent$Params = action.params;
        return this.paymentService.paymentsCreateRgnCoinPaymentIntent(paymentIntent).pipe(
          tap(({ data }: any) => {
            if (data) {
              if (paymentIntent.body.paymentProvider === 'forte') {
                launchForte(data);
              }

              if (paymentIntent.body.paymentProvider === 'xsolla') {
                if (data.token) {
                  launchXsolla(data.token);
                }
              }
            }
          }),
          map((response) =>
            PaymentActions.createBundlesPaymentIntentSuccess({ response }),
          ),
          catchError((error) =>
            of(PaymentActions.createBundlesPaymentIntentFailure({ error })),
          ),
        );
      }),
    ),
  );
}