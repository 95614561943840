import { NgModule } from '@angular/core';
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { PrimeNgModule } from './primeng.module';

import { authInterceptor } from '@app/core/interceptors/auth.interceptor';
import { ApiService } from '@app/core/services/api.service';
import { CommonService } from '@services/common.service';

import { SwiperDirective } from './directives/swiper.directive';

import { CompactNumberPipe } from './pipes/compact-number.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { UrlDomainPipe } from './pipes/url-domain.pipe';

import { HeaderComponent } from './components/header/header.component';
import { DappMiniBlockComponent } from './components/dapp-mini-block/dapp-mini-block.component';
import { DappMidBlockComponent } from './components/dapp-mid-block/dapp-mid-block.component';

import { PaginationComponent } from './components/pagination/pagination.component';
import { PopularDappsComponent } from './components/popular-dapps/popular-dapps.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';

import { AssetMiniComponent } from './components/asset-mini/asset-mini.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { InfoPopupComponent } from './components/info-popup/info-popup.component';
import { ReviewViewPopupComponent } from './components/review-view-popup/review-view-popup.component';
import { ReviewEditPopupComponent } from './components/review-edit-popup/review-edit-popup.component';
import { WalletPopupComponent } from './components/wallet-popup/wallet-popup.component';
import { FixedNumberPipe } from './pipes/fixed-number.pipe';
import { GameSelectorComponent } from './components/game-selector/game-selector.component';
import { AssetPropertiesComponent } from './components/asset-properties/asset-properties.component';
import { AssetFilterComponent } from './components/asset-filter/asset-filter.component';
import { ProfileComponent } from '@app/pages/profile/profile.component';

const components = [
  ProfileComponent,
  HeaderComponent,
  SwiperDirective,
  DappMiniBlockComponent,
  DappMidBlockComponent,
  PaginationComponent,
  CompactNumberPipe,
  PopularDappsComponent,
  CustomSelectComponent,
  GameSelectorComponent,
  AssetMiniComponent,
  CustomCheckboxComponent,
  InfoPopupComponent,
  ReviewViewPopupComponent,
  ReviewEditPopupComponent,
  WalletPopupComponent,
  AssetPropertiesComponent,
  AssetFilterComponent,
];

const pipes = [EllipsisPipe, UrlDomainPipe, FixedNumberPipe];

const modules = [
  CommonModule,
  RouterModule,
  DialogModule,
  ReactiveFormsModule,
  FormsModule,
  CdkMenuModule,
  PrimeNgModule,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    ApiService,
    CommonService,
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd MMM, yyyy' },
    },
  ],
  exports: [...components, ...pipes, ...modules],
})
export class SharedModule {}
