import { createReducer, on } from '@ngrx/store';
import {
  ReadyGGCurrency,
  ReadyGGImageUrl,
  ReadyGGProjectData,
  ReadyGGUserData,
} from '@readygg/ng-api';
import { UserActions } from '../actions/user.actions';

export interface UserState {
  appId: string | null;
  profile: ReadyGGUserData | null;
  displayName: string | null;
  profilePicture: ReadyGGImageUrl | null;
  balance: number;
  userCurrencies: ReadyGGCurrency[];
  loading: boolean;
  loadingProjects: boolean;
  projects: ReadyGGProjectData[] | undefined;
  error: any;
}

export const initialState: UserState = {
  appId: null,
  profile: null,
  displayName: null,
  profilePicture: null,
  balance: 0,
  userCurrencies: [],
  loading: true,
  loadingProjects: false,
  projects: [],
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.getUserProfile, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.getUserProfileSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(UserActions.getUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(UserActions.updateUsernameSuccess, (state, { displayName }) => ({
    ...state,
    displayName,
  })),
  on(UserActions.updateUsernameFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserActions.uploadProfilePictureSuccess, (state, { profilePicture }) => ({
    ...state,
    profilePicture,
  })),
  on(UserActions.updateBalance, (state, { balance }) => ({
    ...state,
    balance,
  })),
  on(UserActions.getUserCurrenciesSuccess, (state, { userCurrencies }) => ({
    ...state,
    userCurrencies,
    loading: false,
  })),
  on(UserActions.getUserCurrenciesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(UserActions.getProjects, (state) => ({
    ...state,
    loadingProjects: true,
  })),
  on(UserActions.getProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
    loadingProjects: false,
  })),
  on(UserActions.getProjectsFailure, (state, { error }) => ({
    ...state,
    error,
    loadingProjects: false,
  })),
  on(UserActions.setAppId, (state, appId) => ({
    ...state,
    appId: appId.params,
  })),
);
