declare global {
  interface Window {
    initFortePaymentsWidget?: (params: any) => void;
  }
}

export function launchForte(data: any) {
  (function init() {
    if (window.initFortePaymentsWidget) {
      window.initFortePaymentsWidget({
        containerId: 'forte-payments-widget-container',
        data,
      });
    } else {
      setTimeout(init, 10);
    }
  })();
}