import { createAction, props } from '@ngrx/store';
import { Asset } from '@app/models/asset';
import { MarketplaceCreateOffer$Params } from '@readygg/ng-api/fn/marketplace/marketplace-create-offer';
import { InventoryV2GetTradableInventoryItems$Params } from '@readygg/ng-api/fn/inventory-v-2/inventory-v-2-get-tradable-inventory-items';
import { InventoryV2GetById$Params } from '@readygg/ng-api/fn/inventory-v-2/inventory-v-2-get-by-id';
import { ReadyGGMarketplaceOfferData } from '@readygg/ng-api';
import { MarketplaceDeleteOffer$Params } from '@readygg/ng-api/fn/marketplace/marketplace-delete-offer';

export const loadInventoryItems = createAction(
  '[Inventory] Load Inventory Items',
  props<{ params: InventoryV2GetTradableInventoryItems$Params }>(),
);

export const loadInventoryItemsSuccess = createAction(
  '[Inventory] Load Inventory Items Success',
  props<{
    assets: Asset[];
  }>(),
);

export const loadInventoryItemsFailure = createAction(
  '[Inventory] Load Inventory Items Failure',
  props<{ error: any }>(),
);

export const getInventoryAssetById = createAction(
  '[Inventory] Get Inventory Asset By ID',
  props<{ params: InventoryV2GetById$Params }>(),
);

export const getInventoryAssetByIdSuccess = createAction(
  '[Inventory] Get Inventory Asset By ID Success',
  props<{ asset: Asset }>(),
);

export const getInventoryAssetByIdFailure = createAction(
  '[Inventory] Get Inventory Asset By ID Failure',
  props<{ error: any }>(),
);

export const createSellingOffer = createAction(
  '[Inventory] Create Selling Offer',
  props<{ params: MarketplaceCreateOffer$Params }>(),
);

export const createSellingOfferSuccess = createAction(
  '[Inventory] Create Selling Offer Success',
  props<{ offer: ReadyGGMarketplaceOfferData }>(),
);

export const createSellingOfferFailure = createAction(
  '[Inventory] Create Selling Offer Failure',
  props<{ error: any }>(),
);

export const deleteSellingOffer = createAction(
  '[Inventory] Delete Selling Offer',
  props<{ params: MarketplaceDeleteOffer$Params }>(),
);

export const deleteSellingOfferSuccess = createAction(
  '[Inventory] Delete Selling Offer Success',
  props<{ offer: ReadyGGMarketplaceOfferData }>(),
);

export const deleteSellingOfferFailure = createAction(
  '[Inventory] Delete Selling Offer Failure',
  props<{ error: any }>(),
);

export const InventoryActions = {
  loadInventoryItems,
  loadInventoryItemsSuccess,
  loadInventoryItemsFailure,
  getInventoryAssetById,
  getInventoryAssetByIdSuccess,
  getInventoryAssetByIdFailure,
  createSellingOffer,
  createSellingOfferSuccess,
  createSellingOfferFailure,
  deleteSellingOffer,
  deleteSellingOfferSuccess,
  deleteSellingOfferFailure,
};
