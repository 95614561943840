import { createReducer, on } from '@ngrx/store';
import { ReadyGGRgnCoinEconomyProduct } from '@readygg/ng-api';
import * as PaymentActions from '../actions/payment.actions';

export interface PaymentState {
  coinBundles: ReadyGGRgnCoinEconomyProduct[],
  loading: boolean
}

const initialState: PaymentState = {
  coinBundles: [],
  loading: false,
};

export const paymentReducer = createReducer(
  initialState,
  on(PaymentActions.fetchCoinBundles, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PaymentActions.fetchCoinBundlesSuccess, (state, { coinBundles }) => ({
    ...state,
    coinBundles,
    loading: false,
    error: null,
  })),
  on(PaymentActions.fetchCoinBundlesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);