import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { UserUpdateDisplayName$Params } from '@readygg/ng-api/fn/user-data/user-update-display-name';
import { MessageService } from 'primeng/api';

import { AuthService } from '@app/core/services/auth.service';
import { UserActions } from '@app/state/actions/user.actions';
import { AppState } from '@app/state/app.state';
import { selectAppId } from '@app/state/selectors/user.selectors';
import { UserUploadProfilePicture$Params } from '@readygg/ng-api/fn/user-data/user-upload-profile-picture';
import { convertToBase64 } from '@app/utils/file-utils';
import { ReadyGGUserProfileData } from '@readygg/ng-api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  private readonly authService = inject(AuthService);
  @Input() open = false;
  @Output() closeModal = new EventEmitter<void>();
  appId: string | null = null;
  user: ReadyGGUserProfileData | null = null;
  base64String: string | null = null;
  isImageUploaded = false;

  form = new UntypedFormGroup({
    profilePicture: new FormControl<Blob | null>(null),
    username: new FormControl<string>('', [Validators.required]),
  });

  constructor(private messageService: MessageService, private store: Store<AppState>) {
    this.store
      .pipe(select(selectAppId), takeUntilDestroyed())
      .subscribe((data) => {
        this.appId = data;
      });
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.user = user;

      this.form.patchValue({
        username: this.user?.displayName || '',
      });
    });
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      convertToBase64(file).then(base64 => {
        this.base64String = base64.split(',')[1];

        if (this.user) {
          if (!this.user.profilePicture) {
            this.user.profilePicture = { source: '', large: '', medium: '', small: '' };
          }

          this.user.profilePicture.source = base64;
          this.isImageUploaded = true;        
        }
      }).catch(() => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'We weren\'t able to upload your profile picture, please try again later.' });
      });
    }
  }

  saveProfile() {
    if (this.form.get('profilePicture')?.dirty) {
      const params: UserUploadProfilePicture$Params = {
        body: {
          base64String: this.base64String!,
        },
      };

      this.store.dispatch(UserActions.uploadProfilePicture({ params }));
    }

    if (
      this.form.get('username')?.dirty &&
      this.form.get('username')?.touched
    ) {
      const params: UserUpdateDisplayName$Params = {
        body: {
          displayName: this.form.get('username')?.value.trim() || '',
        },
      };

      this.store.dispatch(UserActions.updateUsername({ params }));
    }

    this.closeModal.emit();
  }
}
