import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomSelectOption } from '@app/constants/all';
import { UserActions } from '@app/state/actions/user.actions';
import { AppState } from '@app/state/app.state';
import {
  selectProjects,
  selectAppId,
  selectProjectsLoadingState,
} from '@app/state/selectors/user.selectors';
import { toKebabCase } from '@app/utils/mappers';
import { Store, select } from '@ngrx/store';
import { ProjectsGetByTags$Params } from '@readygg/ng-api/fn/projects/projects-get-by-tags';
import { combineLatest } from 'rxjs';

interface OptionItem {
  name: string,
  id: string | null
}

@Component({
  selector: 'app-game-selector',
  templateUrl: './game-selector.component.html',
})
export class GameSelectorComponent implements OnInit {
  selection: OptionItem | undefined;
  appId: string | null = null;
  projects: OptionItem[] | undefined;
  loadingProjects = true;
  images: any = {};
  options: CustomSelectOption[] = [];
  gameIconAvailable = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const projects$ = this.store.pipe(
      select(selectProjects),
      takeUntilDestroyed(),
    );
    const appId$ = this.store.pipe(select(selectAppId), takeUntilDestroyed());

    combineLatest([projects$, appId$]).subscribe(([projects, appId]) => {
      this.projects = projects?.map((project) => {
        this.images[project.name] = toKebabCase(project.name.replace(/\s/g, ''));
        return {
          name: project.name,
          id: project.id,
        };
      });

      this.projects = [{ name: 'All Games', id: null },
        ...(this.projects || [])];

      this.appId = appId;
      if (!this.projects) return;

      if (!this.appId) {
        this.selection = this.projects[0];
      } else {
        this.selection = this.projects.find(
          (project) => project?.id === this.appId,
        );
      }
    });

    store
      .pipe(select(selectProjectsLoadingState), takeUntilDestroyed())
      .subscribe((data) => {
        this.loadingProjects = data;
      });
  }

  ngOnInit(): void {
    this.getter();
  }

  gameIconLoad() {
    this.gameIconAvailable = true;
  }

  gameIconMissing() {
    this.gameIconAvailable = false;
  }

  clearSelection() {
    this.appId = null;

    this.store.dispatch(
      UserActions.setAppId({
        params: '',
      }),
    );
  }

  setter(event: any) {
    const queryParams = { ...this.route.snapshot.queryParams }; // Get existing query params
    let queryParamsHandling: any;

    if (event.value?.id) {
      // If event.value.id is not null, update the appId query parameter
      queryParams['appId'] = event.value.id;
      queryParamsHandling = 'merge';
    } else {
      // If event.value.id is null, remove the appId query parameter
      this.clearSelection();
      queryParamsHandling = '';
      delete queryParams['appId'];
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling,
    });
  }

  getter() {
    const params: ProjectsGetByTags$Params = {
      body: {
        tags: ['Marketplace'],
        limit: 100,
      },
    };
    this.store.dispatch(UserActions.getProjects({ params }));
  }
}