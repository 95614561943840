import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BehaviorSubject,
  catchError,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
} from 'rxjs';

import { ReadyGGUserData, ReadyGGUserProfileData } from '@readygg/ng-api';
import { IAuthNewRefreshResponse } from '../../models/auth.interface';
import { environment } from '../../../environment/environment';

interface UserCredentials {
  token: string;
  userId: string;
}

const LSK_REFRESH_TOKEN = 'refreshToken';
const LSK_ID_TOKEN = 'idToken';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userCredentials = new BehaviorSubject<UserCredentials | null>(null);
  private userData = new BehaviorSubject<ReadyGGUserProfileData | null>(null);
  private authWindow: Window | null = null;

  user$ = this.userData.asObservable();
  isAuthenticated$ = this.userCredentials.pipe(map((data) => !!data));

  // we have to define handler this way to correctly remove with removeEventListener()
  private messagesHandler = (event: MessageEvent) =>
    this.onWindowMessage(event);

  constructor(
    private readonly _http: HttpClient,
    private readonly _router: Router,
    private readonly _injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
  ) { }

  async init() {
    let init = true;

    this.userCredentials
      .pipe(
        filter(() => !init),
        switchMap((data) => {
          if (data) {
            return this.loadUserFullProfile(data.userId);
          } else {
            localStorage.removeItem(LSK_REFRESH_TOKEN);
            // this._injector.get(WalletService).clearWallet();
            this.userData.next(null);
            return of(null);
          }
        }),
      )
      .subscribe((userData) => {
        if (userData) {
          this.userData.next(userData.result);
        } else {
          this.userData.next({
            bio: '',
            displayName: '',
            email: '',
            userId: '',
            profilePicture: {},
            lastAppPackageName: '',
            invisibleStatus: false,
            currencies: [],
          } as ReadyGGUserProfileData);
        }
      });

    init = false;
    await this.refreshToken();
  }

  public signOut(): void {
    this.userCredentials.next(null);
    this.userData.next(null);
  }

  private loadUserProfile() {
    const url = `${environment.apiUrl}/user-getProfile`;
    const data = {
      version: 2,
      appId: environment.projectId,
      userId: this.userCredentials.value!.userId,
    };

    return this._http.post<ReadyGGUserData>(url, data).pipe(
      map((userData) => userData),
      catchError((err) => {
        console.log('load profile failed', err);

        return of(null);
      }),
    );
  }

  public loadUserFullProfile(userId: string): Promise<any> {
    const url = `${environment.apiUrl}/user-getFullProfile`;
    const data = {
      data: {
        version: 2,
        userId: userId,
        allApps: true,
      },
    };

    return firstValueFrom(this._http.post<ReadyGGUserProfileData>(url, data));
  }

  public async refreshToken() {

    const refreshToken = localStorage.getItem(LSK_REFRESH_TOKEN);
    const passedIdToken = localStorage.getItem(LSK_ID_TOKEN);

    console.log('Refresh token: ', refreshToken);

    if (!refreshToken) return null;

    const data = { refreshToken };
    console.log('data: ', data);

    const url = `${environment.apiUrl}/user-refreshTokens`;

    try {
      const response: IAuthNewRefreshResponse = await firstValueFrom(
        this._http.post<IAuthNewRefreshResponse>(url, data),
      );
      console.log('Response on refresh token: ', response);

      const idToken = passedIdToken || response.idToken;

      this.setUserCredentials(
        idToken,
        response.refreshToken,
        response.userId,
        true,
      );

      return idToken; // Ensure the method returns the new token
    } catch (err) {
      console.log('refresh token failed');
      this.signOut();
      return null; // Return null in case of error
    }
  }

  public setUserCredentials(
    token: string,
    refreshToken: string,
    userId: string,
    remember: boolean,
  ): void {
    this.userCredentials.next({ token, userId });

    if (remember && refreshToken) {
      localStorage.setItem(LSK_REFRESH_TOKEN, refreshToken);
    }

    if (remember && token) {
      localStorage.setItem(LSK_ID_TOKEN, token);
    }

    // Load the full user profile after setting credentials
    this.loadUserFullProfile(userId)
      .then((userProfile) => {
        this.userData.next(userProfile.result);
      })
      .catch((error) => {
        console.error('Failed to load full user profile:', error);
      });
  }

  public getUserToken(): string | null {
    return this.userCredentials.value ? this.userCredentials.value.token : null;
  }

  public getUserId(): string {
    return this.userCredentials.value!.userId;
  }

  public isAuthenticated(): boolean {
    return !!this.userCredentials.value;
  }

  public mapError(error: 'INVALID_EMAIL' | 'EMAIL_NOT_FOUND') {
    const defaultError = {
      message: 'Something went wrong, please try again later',
      type: 'error',
    };

    const errorMap: Record<string, { message: string; type: string }> = {
      INVALID_EMAIL: {
        message: 'E-mail not found',
        type: 'accent',
      },
      EMAIL_NOT_FOUND: {
        message: 'E-mail not found',
        type: 'accent',
      },
      INVALID_PASSWORD: {
        message: "Password doesn't match",
        type: 'accent',
      },
      INVALID_ID_TOKEN: {
        message: 'Token is invalid, try the signin process again',
        type: 'error',
      },
      EMAIL_EXISTS: {
        message: 'This e-mail already exists',
        type: 'error',
      },
      TOO_MANY_ATTEMPTS_TRY_LATER: {
        message: "You've tried too many times, try again later",
        type: 'error',
      },
    };

    return Object.prototype.hasOwnProperty.call(errorMap, error)
      ? errorMap[error]
      : defaultError;
  }

  userDataForPayments() {
    return {
      id: this.userData.value!.userId,
      email: this.userData.value!.email,
    };
  }

  runOAuth(tabName = 'signin', forceLogout = false) {
    const w = 600;
    const h = 550;
    const dualScreenLeft =
      typeof window.screenLeft !== 'undefined'
        ? window.screenLeft
        : window.screenX;

    const dualScreenTop =
      typeof window.screenTop !== 'undefined'
        ? window.screenTop
        : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;

    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const windowOptions = `location=yes,status=yes,scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`;

    window.addEventListener('message', this.messagesHandler);

    if (this.authWindow) {
      this.authWindow.close();
      this.authWindow = null;
    }

    const forceLogoutToken = forceLogout ? '&forceLogout=true' : '';
    const base = new URL(window.location.href);


    this.authWindow = window.open(
      `${environment.auth.url}/?url_redirect=${base.href
      }&returnSecureToken=true&theme=dark&appId=${this.getAppId()}${forceLogoutToken}&view=${tabName}`,
      '_blank',
      windowOptions,
    );
  }

  getUrlRedirect(redirectUrl: string, token: string | null) {
    return token ? `${redirectUrl}?token=${token}` : redirectUrl;
  }

  getReturnToGamePath(redirect: string) {
    const token = localStorage.getItem(LSK_REFRESH_TOKEN);
    const returnUrl =
      redirect + '?token=' + token + '&appId=' + this.getAppId();

    if (!returnUrl.startsWith('http://') && !returnUrl.startsWith('https://')) {
      return 'https://' + returnUrl;
    } else {
      return returnUrl;
    }
  }


  getAppId(): string {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.has('appId')
      ? searchParams.get('appId') || ''
      : environment.projectId;
  }

  onWindowMessage(event: MessageEvent) {
    if (event.origin !== environment.auth.url) return;

    const session = event.data.session;
    console.log({ session });

    if (session) {
      this.setUserCredentials(
        session.idToken,
        session.refreshToken,
        session.userId,
        true,
      );

      if (this.authWindow) {
        this.authWindow.close();
        this.authWindow = null;
        window.removeEventListener('message', this.messagesHandler);
      }
    }
  }

  public async confirmGdpr() {
    // TODO: not clear if we still need it and what to call
    return 'temporary stub';
  }

  public isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeJwt(token);
    if (!decodedToken) return true;

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime;
  }

  private decodeJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }
}
