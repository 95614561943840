import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export function isLogged() {
  const authService = inject(AuthService);

  if (authService.isAuthenticated()) {
    return true;
  } else {
    authService.runOAuth('signin', true);
    return false;
  }
}
