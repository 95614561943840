import { Injectable } from '@angular/core';

const lang = 'en';

const errors: Record<string, Record<string, string>> = {
  en: {
    'failed-precondition-blockchain-api': 'Failed to call the blockchain api.',
    'unauthenticated-no-auth-header': 'Authorization header is missing.',
    'unauthenticated-token-expired': 'Your session has expired, please log in again.',
    'unauthenticated-failed-to-verify-token': 'Token verification failed, please try again.',
    'invalid-argument-appId': 'Invalid app ID provided.',
    'invalid-argument-itemIds': 'Invalid item IDs provided.',
    'invalid-argument-currencies': 'Invalid currency specified.',
    'invalid-argument-offerId': 'Invalid offer ID.',
    'permission-denied-offer-appIds-not-contains-appId': 'You are not authorized to access this offer.',
    'permission-denied-item-appIds-not-contains-appId': 'You are not authorized to access this item.',
    'not-found-offer': 'The offer was not found.',
    'not-found-virtual-items': 'The virtual items were not found.',
    'failed-precondition-offer-not-available': 'This offer is not available.',
    'failed-precondition-item-in-lootbox': 'The item is part of a lootbox and cannot be bought separately.',
    'failed-precondition-nft-item-out-of-stock': 'The NFT item is out of stock.',
    'failed-precondition-nft-item-tokenId-required': 'A token ID is required for this NFT item.',
    'failed-precondition-nonNFT-item-out-of-stock': 'The item is out of stock.',
    'failed-precondition-inventory-item-on-sale': 'The inventory item is already on sale.',
    'failed-precondition-no-currencies': 'You do not have any currencies available.',
    'failed-precondition-prices-helper-error': 'An error occurred while retrieving the prices.',
    'failed-precondition-offer-prices-broken': 'The offer prices are invalid.',
    'failed-precondition-virtual-items-prices-broken': 'The virtual item prices are invalid.',
    'failed-precondition-no-custodial-wallet': 'You do not have a custodial wallet set up.',
    'failed-precondition-no-non-custodial-wallet': 'You do not have a non-custodial wallet set up.',
    'failed-precondition-no-primary-wallet': 'You do not have a primary wallet set up.',
    'failed-precondition-not-enough-currencies': 'You do not have enough currencies for this transaction.',
    'failed-precondition-max-quantity-per-user-reached': 'You have reached the maximum quantity allowed for this item.',
    'permission-denied-user-is-not-the-owner': 'You are not the owner of this offer.',
    'permission-denied-offer-not-on-sale': 'This offer is not currently on sale.',
    'permission-denied-offer-is-in-marketplace-pending-status': 'This offer is pending in the marketplace.',
    'permission-denied-inventory-item-not-on-sale': 'The inventory item is not for sale.',
    'permission-denied-user-is-the-owner': 'You cannot buy your own offer.',
    'not-found-inventory-items': 'Inventory items not found.',
    'not-found-user-stats': 'User stats not found.',
    'permission-denied-transaction-is-not-marketplace-pending': 'The transaction is not pending in the marketplace.',
    'permission-denied-inventory-item-is-in-marketplace-pending-status': 'The inventory item is pending in the marketplace.',
    'not-found-transaction': 'Transaction not found.',
    'not-found-buyer-stats': 'Buyer stats not found.',
    'not-found-seller-stats': 'Seller stats not found.',
    'invalid-argument-inventoryItemId': 'Invalid inventory item ID.',
    'invalid-argument-price': 'Invalid price provided.',
    'already-exists-offer': 'An offer already exists for this item.',
    'failed-precondition-virtual-item-is-stackable': 'This virtual item is stackable and cannot be used in this way.',
    'invalid-argument-queryStatuses': 'Invalid query statuses provided.',
    'invalid-argument-startAfter': 'Invalid startAfter parameter.',
    'invalid-argument-limit': 'Invalid limit provided.',
    'invalid-argument-orderByDirection': 'Invalid order by direction.',
    'invalid-argument-orderByField': 'Invalid order by field.',
    'invalid-argument-transactionId': 'Invalid transaction ID.',
    'invalid-argument-result': 'Invalid result provided.',
    'failed-precondition-loot-box-name-not-unique': 'The loot box name is not unique.',
    'failed-precondition-loot-box-partition-size-negative': 'The loot box partition size cannot be negative.',
    'not-found-loot-box': 'Loot box not found.',
    'not-found-loot-box-partition': 'Loot box partition not found.',
  },
};

export class CustomError extends Error {
  code: string | undefined;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  getError(error: any): any {
    if (typeof error === 'string') {
      return error;
    }

    if (error && error.code) {
      const translatedMessage = errors[lang][error.code];

      if (translatedMessage) {
        return translatedMessage;
      }
    }

    if (error && error.message) {
      return error.message;
    }

    return error;
  }
}