import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { environment } from '../../../environment/environment';

import { ReadyGGApiConfiguration } from '@readygg/ng-api/ready-gg-api-configuration';
import {
  CurrencyService,
  ProjectsService,
  UserDataService,
} from '@readygg/ng-api';
import { Action } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  private cachedProjectsRequest$: Observable<Action> | undefined;

  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserProfile),
      switchMap((action) =>
        this.userService.userGetProfile(action.params).pipe(
          map((response) => {
            return UserActions.getUserProfileSuccess({
              profile: response,
            });
          }),
          catchError((error) =>
            of(UserActions.getUserProfileFailure({ error })),
          ),
        ),
      ),
    ),
  );

  updateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUsername),
      mergeMap((action) =>
        this.userService.userUpdateDisplayName(action.params).pipe(
          map((response) => {
            return UserActions.updateUsernameSuccess({
              displayName: response.displayName!,
            });
          }),
          catchError((error) =>
            of(UserActions.updateUsernameFailure({ error })),
          ),
        ),
      ),
    ),
  );

  uploadProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.uploadProfilePicture),
      mergeMap((action) =>
        this.userService.userUploadProfilePicture(action.params).pipe(
          map((response) => {
            return UserActions.uploadProfilePictureSuccess({
              profilePicture: response,
            });
          }),
          catchError((error) =>
            of(UserActions.uploadProfilePictureFailure({ error })),
          ),
        ),
      ),
    ),
  );

  getUserCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserCurrencies),
      mergeMap((action) =>
        this.currencyService.currencyGetUserCurrencies(action.params).pipe(
          map((response) => {
            return UserActions.getUserCurrenciesSuccess({
              userCurrencies: response.userCurrencies!,
            });
          }),
          catchError((error) =>
            of(UserActions.getUserCurrenciesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getProjects),

      switchMap((action) => {
        if (!this.cachedProjectsRequest$) {
          this.cachedProjectsRequest$ = this.projectsService
            .projectsGetByTags(action.params)
            .pipe(
              map((response) => {
                return UserActions.getProjectsSuccess({
                  projects: response.projects,
                });
              }),
              catchError((error) =>
                of(UserActions.getProjectsFailure({ error })),
              ),
              shareReplay(1),
            );
        }

        return this.cachedProjectsRequest$;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserDataService,
    private currencyService: CurrencyService,
    private projectsService: ProjectsService,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };

    this.userService = new UserDataService(config, inject(HttpClient));
    this.currencyService = new CurrencyService(config, inject(HttpClient));
    this.projectsService = new ProjectsService(config, inject(HttpClient));
  }
}
