import {
  DApp,
  DAppHomePageData,
  DAppPlatforms,
  DAppQueryTypes,
  Icon,
  SocialNetworks,
  SocialUrl,
} from '@app/models/api';

export function dAppPlatformsToIcons(platforms: string[]) {
  return platforms.reduce((acc, key) => {
    let name!: string, iconClass!: string;

    switch (key) {
      case DAppPlatforms.Android:
        name = 'Android';
        iconClass = 'icon-android';
        break;

      case DAppPlatforms.Ios:
        name = 'iOS';
        iconClass = 'icon-macos';
        break;

      case DAppPlatforms.Windows:
        name = 'Windows';
        iconClass = 'icon-desktop';
        break;

      default:
        return acc;
    }

    acc.push({ name, iconClass });

    return acc;
  }, [] as Icon[]);
}

export function socialNetworksToIcons(urls: SocialUrl[]) {
  return urls.reduce((acc, url) => {
    let name!: string, iconClass!: string, link!: string;

    switch (url.networkId) {
      case SocialNetworks.Discord:
        name = 'Discord';
        iconClass = 'icon-discord';
        link = url.url;
        break;

      case SocialNetworks.Facebook:
        name = 'Facebook';
        iconClass = 'icon-facebook';
        link = url.url;
        break;

      case SocialNetworks.Instagram:
        name = 'Instagram';
        iconClass = 'icon-instagram';
        link = url.url;
        break;

      case SocialNetworks.Telegram:
        name = 'Telegram';
        iconClass = 'icon-telegram';
        link = url.url;
        break;

      case SocialNetworks.X:
        name = 'X';
        iconClass = 'icon-x';
        link = url.url;
        break;

      default:
        return acc;
    }

    acc.push({ name, iconClass, link });

    return acc;
  }, [] as Icon[]);
}

export function fetchAppsFromBatchResponse(
  data: DAppHomePageData,
  type: DAppQueryTypes,
  limit = 0,
): DApp[] {
  const queryData = data.queryResults.find((item) => item.queryType === type);
  if (!queryData) return [];

  const items = queryData.dappIds.reduce((acc, appId) => {
    const app = data.dapps.find((item) => item.id === appId);

    if (app) acc.push(app);

    return acc;
  }, [] as DApp[]);

  return limit > 0 ? items.slice(0, limit) : items;
}

export function toKebabCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Insert hyphen between lowercase and uppercase letters
    .toLowerCase(); // Convert entire string to lowercase
}