import { createAction, props } from '@ngrx/store';
import {
  ReadyGGCurrency,
  ReadyGGProjectData,
  ReadyGGUserData,
  ReadyGGUserProfilePicture,
} from '@readygg/ng-api';
import { CurrencyGetUserCurrencies$Params } from '@readygg/ng-api/fn/currency/currency-get-user-currencies';
import { UserGetProfile$Params } from '@readygg/ng-api/fn/user-data/user-get-profile';
import { UserUpdateDisplayName$Params } from '@readygg/ng-api/fn/user-data/user-update-display-name';
import { UserUploadProfilePicture$Params } from '@readygg/ng-api/fn/user-data/user-upload-profile-picture';

export const getUserProfile = createAction(
  '[User] Get User Profile',
  props<{ params: UserGetProfile$Params }>(),
);

export const getUserProfileSuccess = createAction(
  '[User] Get User Profile Success',
  props<{ profile: ReadyGGUserData }>(),
);

export const getUserProfileFailure = createAction(
  '[User] Get User Profile Failure',
  props<{ error: any }>(),
);

export const updateUsername = createAction(
  '[User] Update Username',
  props<{ params: UserUpdateDisplayName$Params }>(),
);

export const updateUsernameSuccess = createAction(
  '[User] Update Username Success',
  props<{ displayName: string }>(),
);

export const updateUsernameFailure = createAction(
  '[User] Update Username Failure',
  props<{ error: any }>(),
);

export const uploadProfilePicture = createAction(
  '[User] Update Profile Picture',
  props<{ params: UserUploadProfilePicture$Params }>(),
);

export const uploadProfilePictureSuccess = createAction(
  '[User] Update Profile Picture Success',
  props<{ profilePicture: ReadyGGUserProfilePicture }>(),
);

export const uploadProfilePictureFailure = createAction(
  '[User] Update Profile Picture Failure',
  props<{ error: any }>(),
);

export const updateBalance = createAction(
  '[User] Update Balance',
  props<{ balance: number }>(),
);

export const getUserCurrencies = createAction(
  '[Currency] Get User Currencies',
  props<{ params: CurrencyGetUserCurrencies$Params }>(),
);

export const getUserCurrenciesSuccess = createAction(
  '[Currency] Get User Currencies Success',
  props<{ userCurrencies: ReadyGGCurrency[] }>(),
);

export const getUserCurrenciesFailure = createAction(
  '[Currency] Get User Currencies Failure',
  props<{ error: any }>(),
);

export const getProjects = createAction(
  '[Projects] Get Projects',
  props<{ params: any }>(),
);

export const getProjectsSuccess = createAction(
  '[Projects] Get Projects Success',
  props<{ projects: ReadyGGProjectData[] | undefined }>(),
);

export const getProjectsFailure = createAction(
  '[Projects] Get Projects Failure',
  props<{ error: any }>(),
);

export const setAppId = createAction(
  '[Projects] Set App Id',
  props<{ params: string }>(),
);

export const UserActions = {
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  updateUsername,
  updateUsernameSuccess,
  updateUsernameFailure,
  uploadProfilePicture,
  uploadProfilePictureSuccess,
  uploadProfilePictureFailure,
  updateBalance,
  getUserCurrencies,
  getUserCurrenciesSuccess,
  getUserCurrenciesFailure,
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  setAppId,
};
