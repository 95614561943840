<div class="p-input-icon-left w-full">
  <i class="pi icon-search"></i>
  <input
    pInputText
    type="search"
    class="md:w-28rem w-full"
    [formControl]="searchText"
    placeholder="Type asset name..."
  />
</div>
