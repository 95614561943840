/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { Dialog } from '@angular/cdk/dialog';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { AppState } from './state/app.state';
import { UserActions } from './state/actions/user.actions';
import { pollingStoppedSuccess } from './state/actions/marketplace.actions';
import { CommonService } from './core/services/common.service';
import { StorageService } from './core/services/storage.service';
import {
  ReadyGGCurrency,
  ReadyGGProjectData,
  ReadyGGUserProfileData,
} from '@readygg/ng-api';

interface MenuItem {
  title: string;
  icon: string;
  link: string | string[];
  disabled: boolean;
  forLogged?: boolean;
}

const allMenu: MenuItem[] = [
  // TODO: hide home & explore for now
  // {
  //   title: 'Home',
  //   icon: 'icon-home',
  //   link: '/',
  //   disabled: false,
  // },
  // {
  //   title: 'Explore',
  //   icon: 'icon-explore',
  //   link: '/explore',
  //   disabled: false,
  // },
  {
    title: 'Marketplace',
    icon: 'icon-cart',
    link: '/marketplace',
    disabled: false,
  },
  {
    title: 'Inventory',
    icon: 'icon-inventory',
    link: '/inventory',
    disabled: false,
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  readonly commonService = inject(CommonService);
  readonly dialog = inject(Dialog);
  readonly destroyRef = inject(DestroyRef);

  private updateMenu = new Subject<void>();

  title = 'dapp';
  menu: MenuItem[] = [];
  user: ReadyGGUserProfileData | null = null;
  searchCtrl = new FormControl<string>('');
  projects: ReadyGGProjectData[] | undefined;
  appId: string | null = null;
  balance = 0;
  currencyIcon = '';
  loading = true;

  returnPath = '';
  redirect: any = new URL(window.location.href).searchParams.get(
    'url_redirect',
  );
  platform: string | null = new URL(window.location.href).searchParams.get(
    'platform',
  );
  fromTelegram = false;

  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private store: Store<AppState>,
  ) {
    this.fromTelegram = this.platform === 'webgl';
  }

  ngOnInit(): void {
    this.initializeUserSubscription();
    this.handleSearchControl();
    this.subscribeToMenuUpdates();

    this.actions$
      .pipe(ofType(UserActions.updateBalance,
      ), takeUntilDestroyed(this.destroyRef))
      .subscribe((action) => {
        this.balance = action.balance;
      });

    this.actions$
      .pipe(ofType(
        UserActions.getUserCurrenciesSuccess,
      ), takeUntilDestroyed(this.destroyRef))
      .subscribe((action) => {
        this.balance = action.userCurrencies[0].quantity;
      });

    this.actions$
      .pipe(ofType(pollingStoppedSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'New asset acquired!',
          detail: 'The transaction has been confirmed successfully!',
          closable: true,
          life: 5000,
        });
      });
  }

  private initializeUserSubscription(): void {
    this.authService.user$.subscribe((user) => {
      this.user = user;
      this.updateMenu.next();
      this.handleQueryParams();
      if (user) this.returnToGame();
    });
  }

  returnToGame() {
    const url = this.authService.getReturnToGamePath(this.redirect);
    this.returnPath = url;
  }

  private handleQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.appId = params.get('appId');

      if (this.appId) {
        this.setAppId(this.appId);
        this.updateCurrencyData();
      }
    });
  }

  private setAppId(appId: string): void {
    this.store.dispatch(UserActions.setAppId({ params: appId }));
  }

  private updateCurrencyData(): void {
    if (this.userHasCurrencies()) {
      const currency = this.getCurrencyByAppId(this.appId!);

      if (currency) {
        // this.balance = currency.quantity;
        this.store.dispatch(UserActions.updateBalance({ balance: currency.quantity }));
        this.currencyIcon = this.storageService.getCurrencyIcon(this.appId!);
      }
    }
  }

  private userHasCurrencies(): boolean {
    return (
      this.user! && this.user!.currencies && this.user!.currencies.length > 0
    );
  }

  private getCurrencyByAppId(appId: string): ReadyGGCurrency | undefined {
    return this.user!.currencies.find((c) => c.appIds![0] === appId);
  }

  private handleSearchControl(): void {
    // If the search functionality is needed, uncomment this section
    // this.searchCtrl.valueChanges
    //   .pipe(debounceTime(TEXT_FIELD_DEBOUNCE_TIME))
    //   .subscribe((search) => {
    //     this.commonService.updateSearch(search);
    //     this.searchCtrl.setValue(null, { emitEvent: false });
    //     this.router.navigate(['/explore']);
    //   });
  }

  private subscribeToMenuUpdates(): void {
    this.updateMenu.subscribe(() => {
      this.menu = allMenu.filter((item) => {
        let allow = true;

        if (item.forLogged) {
          allow &&= !!this.user;
        }

        return allow;
      });
    });

    this.updateMenu.next();
  }

  openAuthPopup() {
    this.authService.runOAuth('signin', true);
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['/']);
  }

  goToInventory() {
    if (this.router.url !== '/inventory') {
      this.router.navigate(['/inventory'], { queryParamsHandling: 'preserve' });
    }
  }
}
