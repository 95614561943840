import { createReducer, on } from '@ngrx/store';
import * as MarketPlaceActions from '../actions/marketplace.actions';
import { Asset } from '@app/models/asset';

export interface MarketPlaceState {
  assets: Asset[];
  currentAsset: Asset | null;
  error: any;
  loading: boolean;
  buying: boolean;
  buyOfferError: any;
}

export const initialState: MarketPlaceState = {
  assets: [],
  currentAsset: null,
  error: null,
  buying: false,
  loading: true,
  buyOfferError: null,
};

export const marketPlaceReducer = createReducer(
  initialState,
  on(MarketPlaceActions.loadMarketPlaceOffers, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(MarketPlaceActions.loadMarketPlaceOffersSuccess, (state, { assets }) => ({
    ...state,
    assets,
    loading: false,
    error: null,
  })),
  on(MarketPlaceActions.loadMarketPlaceOffersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MarketPlaceActions.selectAssetById, (state) => ({
    ...state,
    loading: true,
    error: null,
    buyOfferError: null,
  })),
  on(MarketPlaceActions.selectAssetByIdSuccess, (state, { asset }) => {
    return {
      ...state,
      loading: false,
      assets: [...state.assets, asset],
      currentAsset: asset,
    };
  }),
  on(MarketPlaceActions.selectAssetByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MarketPlaceActions.fetchAssetById, (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(MarketPlaceActions.fetchAssetByIdSuccess, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(MarketPlaceActions.fetchAssetByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MarketPlaceActions.buyOffer, (state) => ({
    ...state,
    buying: true,
    buyOfferError: null,
  })),
  on(MarketPlaceActions.buyOfferSuccess, (state, { transactionData }) => {
    return {
      ...state,
      buying: false,
      assets: state.assets
        .filter((a: Asset) => {
          // Filter out the asset if the status is "confirmed"
          return !(a.id === transactionData.id && transactionData.status === 'confirmed');
        })
        .map((a: Asset) => {
          // Update asset if the status is "pending"
          if (a.id === transactionData.id && transactionData.status === 'marketplace_pending') {
            return {
              ...a,
              status: transactionData.status,
              offerId: transactionData.offerId,
            };
          }

          return a;
        }),
    };
  }),
  on(MarketPlaceActions.buyOfferFailure, (state, { error }) => ({
    ...state,
    buying: false,
    buyOfferError: error,
  })),
  on(MarketPlaceActions.pollingStoppedSuccess, (state, { asset }) => ({
    ...state,
    assets: state.assets.filter(a => a.id !== asset.id),
  })),
);
