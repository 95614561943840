import { FormControl } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-asset-filter',
  templateUrl: 'asset-filter.component.html',

})
export class AssetFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<string>();
  searchText: FormControl = new FormControl('');

  ngOnInit() {
    this.searchText.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => this.filterChanged.emit(this.searchText.value));
  }
}
