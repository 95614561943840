import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { MenuItem } from 'primeng/api';
import { ReadyGGUserProfileData } from '@readygg/ng-api';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { UserActions } from '@app/state/actions/user.actions';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private readonly router = inject(Router);
  isProfileOpen = false;
  @Input() user: ReadyGGUserProfileData | null = null;
  @Input() appId: string | null = null;
  @Input() balance = 0;
  @Input() currencyIcon = '';
  currencyIconAvailable = false;
  returnPath = '';
  redirect: any = new URL(window.location.href).searchParams.get(
    'url_redirect',
  );
  idToken: string | null = new URL(window.location.href).searchParams.get(
    'idToken',
  );
  source: string | null = new URL(window.location.href).searchParams.get(
    'utm_source',
  );
  tokenExpired = false;
  items: MenuItem[] | undefined;

  constructor(private authService: AuthService, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Edit Profile',
        command: () => this.openProfile(),
        icon: 'icon-person',
      },
      {
        label: 'Sign-out',
        icon: 'icon-logout-alt',
        command: () => this.logout(),
      },
    ];


    if (this.idToken) {
      if (this.authService.isTokenExpired(this.idToken)) {
        this.showTokenExpiredMessage();
      } else {
        this.authService.setUserCredentials(this.idToken, '', '', true);
      }
    }

    this.returnToGame();

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.returnToGame();
      }
    });
  }

  currencyIconLoad() {
    this.currencyIconAvailable = true;
  }

  currencyIconMissing() {
    this.currencyIconAvailable = false;
  }

  openAuthPopup() {
    this.authService.runOAuth('signin', true);
  }

  openProfile() {
    this.isProfileOpen = true;
  }

  returnToGame() {
    const url = this.authService.getReturnToGamePath(this.redirect);
    this.returnPath = url;
  }

  showTokenExpiredMessage() {
    this.tokenExpired = true;
  }

  closeProfile() {
    this.isProfileOpen = false;
  }

  saveProfile() {
    this.isProfileOpen = false;
  }

  logout() {
    this.authService.signOut();

    this.store.dispatch(
      UserActions.setAppId({
        params: '',
      }),
    );

    this.router.navigate(['/']);
  }
}
