import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@app/shared/shared.module';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Step 1: Add the following line...
import { register as swiperRegister } from 'swiper/element/bundle';

import { environment } from '../environment/environment';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DappComponent } from './pages/dapp/dapp.component';
import { ExploreComponent } from './pages/explore/explore.component';
import { VaultComponent } from './pages/vault/vault.component';
import { AuthService } from './core/services/auth.service';

import { ApiModule } from '@readygg/ng-api';
import { userReducer } from './state/reducers/user.reducer';
import { UserEffects } from './state/effects/user.effects';
import { marketPlaceReducer } from './state/reducers/marketplace.reducer';
import { MarketPlaceEffects } from './state/effects/marketplace.effects';
import { paymentReducer } from './state/reducers/payment.reducer';
import { PaymentEffects } from './state/effects/payment.effects';

swiperRegister();

function initializeAppFactory(authService: AuthService) {
  return () => authService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DappComponent,
    ExploreComponent,
    VaultComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    ApiModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('marketplace', marketPlaceReducer),
    StoreModule.forFeature('payment', paymentReducer),
    EffectsModule.forFeature([UserEffects]),
    EffectsModule.forFeature([MarketPlaceEffects]),
    EffectsModule.forFeature([PaymentEffects]),

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
