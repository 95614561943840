export function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result as string;
      const img = new Image();
      img.src = base64String;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // Set the canvas size to the image size
          canvas.width = img.width;
          canvas.height = img.height;
          
          // Draw the image onto the canvas without modifying orientation
          ctx.drawImage(img, 0, 0, img.width, img.height);

          // Get the new base64 string from the canvas, which will not include EXIF metadata
          resolve(canvas.toDataURL('image/jpeg'));
        } else {
          // Fallback to returning the original base64 if canvas is unavailable
          resolve(base64String);
        }
      };

      img.onerror = () => reject(new Error('Image load error'));
    };

    reader.onerror = (error) => reject(error);
  });
}
