import { createAction, props } from '@ngrx/store';
import { ReadyGGRgnCoinEconomyProduct } from '@readygg/ng-api';
import { CurrencyGetRgnCoinEconomy$Params } from '@readygg/ng-api/fn/currency/currency-get-rgn-coin-economy';
import { PaymentsCreateMarketplaceOfferPaymentIntent$Params } from '@readygg/ng-api/fn/payments/payments-create-marketplace-offer-payment-intent';
import { PaymentsCreateRgnCoinPaymentIntent$Params } from '@readygg/ng-api/fn/payments/payments-create-rgn-coin-payment-intent';

export const createPaymentIntent = createAction(
  '[Payments] Create Payment Intent',
  props<{ params: PaymentsCreateMarketplaceOfferPaymentIntent$Params }>(),
);

export const createPaymentIntentFailure = createAction(
  '[Payments] Create Payment Intent Failure',
  props<{ error: any }>(),
);

export const createPaymentIntentSuccess = createAction(
  '[Payments] Create Payment Intent Success',
  props<any>(),
);

export const createBundlesPaymentIntent = createAction(
  '[Payments] Create Bundles Payment Intent',
  props<{ params: PaymentsCreateRgnCoinPaymentIntent$Params }>(),
);

export const createBundlesPaymentIntentSuccess = createAction(
  '[Payments] Create Bundles Payment Intent Success',
  props<any>(),
);

export const createBundlesPaymentIntentFailure = createAction(
  '[Payments] Create Bundles Payment Intent Failure',
  props<{ error: any }>(),
);

export const fetchCoinBundles = createAction(
  '[Payments] Fetch Coin Bundles',
  props<{ params: CurrencyGetRgnCoinEconomy$Params }>(),
);

export const fetchCoinBundlesSuccess = createAction(
  '[Payments] Fetch Coin Bundles Success',
  props<{ coinBundles: ReadyGGRgnCoinEconomyProduct[] }>(),
);

export const fetchCoinBundlesFailure = createAction(
  '[Payments] Fetch Coin Bundles Failure',
  props<{ error: any }>(),
);
