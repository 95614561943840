<p-accordion>
  <p-accordionTab header="Properties">
    <div
      class="properties_container"
      *ngFor="let property of properties | keyvalue"
    >
      <div class="properties">
        <div>{{ property.key }}</div>
        <div>{{ property.value }}</div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
