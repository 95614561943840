import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-asset-properties',
  templateUrl: './asset-properties.component.html',
  styleUrls: ['./asset-properties.component.scss'],
})
export class AssetPropertiesComponent {
  @Input() properties: any = {};
}
