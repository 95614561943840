import { Asset } from '@app/models/asset';
import {
  ReadyGGVirtualItemData,
  ReadyGGInventoryItemData,
  ReadyGGProperties,
  ReadyGGPriceInfo,
} from '@readygg/ng-api';

export const findById = (
  virtualItems: ReadyGGVirtualItemData[] | undefined,
  id: string,
) => virtualItems?.find((item) => item.id === id);

export const findInvItemById = (
  inventoryItems: ReadyGGInventoryItemData[] | undefined,
  id: string,
) => inventoryItems?.find((item) => item.virtualItemId === id);

export function isReadyGGProperties(obj: any): obj is ReadyGGProperties {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  if (
    obj.json !== undefined &&
    obj.json !== null &&
    typeof obj.json !== 'string'
  ) {
    return false;
  }

  return true;
}

export function isValidProperties(arr: any[]): arr is ReadyGGProperties[] {
  const isArray = Array.isArray(arr);
  const isNonEmpty = arr.length > 0;
  const allElementsValid = arr.every(isReadyGGProperties);
  return isArray && isNonEmpty && allElementsValid;
}

export const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const getAssetObj = (
  virtualItems: ReadyGGVirtualItemData[] | undefined,
  inventoryItems: ReadyGGInventoryItemData[] | undefined,
  assetData?: {
    appIds: string[] | undefined;
    id: string | undefined;
    prices: ReadyGGPriceInfo[] | undefined;
    status: any; //due to inconsistency between ReadyGGInventoryItemData.status and ReadyGGMarketplaceOfferData.status;
    virtualItemIds: string[] | undefined;
    inventoryItemIds?: string[] | undefined;
    createdAt?: number | undefined;
    updatedAt?: number | undefined;
    quantity?: number | undefined;
    offerId?: string | undefined;
    ownerId?: string | undefined;
  },
): Asset => {
  //get virtual item id from the offer
  const virtualItemId = (assetData?.virtualItemIds || [])[0];

  //find virtual item
  const virtualItem = findById(virtualItems, virtualItemId);

  //find inventory item
  const inventoryItem = findInvItemById(inventoryItems, virtualItemId);

  //Prioritize inventoryItem properties over virtualItem properties
  const properties =
    inventoryItem?.properties && isValidProperties(inventoryItem.properties)
      ? inventoryItem?.properties[0].json
      : virtualItem?.properties && isValidProperties(virtualItem.properties)
        ? virtualItem?.properties[0].json
        : false;

  const parsedProperties = properties && parseJson(properties);

  return {
    appIds: assetData?.appIds || [],
    title: virtualItem?.name || '',
    prices: assetData?.prices ? assetData?.prices : [],
    image: virtualItem?.image,
    id: assetData?.id || '',
    virtualItemId,
    status: assetData!.status,
    ...(parsedProperties ? { properties: parsedProperties } : {}),
    description: virtualItem?.description,
    createdAt: assetData?.createdAt,
    updatedAt: assetData?.updatedAt,
    offerId: assetData?.offerId,
    ownerId: assetData?.ownerId,
    inventoryItemIds: assetData?.inventoryItemIds || [],
  };
};

export const generateRandomString = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};