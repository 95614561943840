<div class="relative h-full">
  <a (click)="assetClickHandler(asset.id)" class="assets__link h-full" [routerLink]="['/marketplace/asset', asset.id]"
    [queryParams]="{ appId: appId }" [ngClass]="{
    'disable': asset.status === 'confirmed'
  }">
    <div class="properties assets__image">
      <ul *ngIf="asset.properties" [ngClass]="{ block: visibleProperties, hidden: !visibleProperties }"
        class="property absolute w-full h-full z-5 p-3 fadeinleft sm:hidden bg-black-alpha-50">
        <li class="list-none text-white" *ngFor="let item of asset.properties | keyvalue">
          {{ item.key }}: {{ item.value }}
        </li>
      </ul>

      <img *ngIf="!asset.image" src="/assets/images/asset-default.png" alt="" />
      <img *ngIf="asset.image" [src]="asset.image.large" alt="" />
      <div *ngIf="asset.status === 'confirmed' || asset.status === 'marketplace_pending'"
        class="absolute bottom-0 left-0 status-label" [ngClass]="{
        'sold': asset.status === 'confirmed',
        'pending': asset.status === 'marketplace_pending'
      }">
        {{ asset.status === 'confirmed' ? 'Sold Out!' : 'Pending' }}
      </div>

    </div>
    <div class="assets__content">
      <div class="assets__use">{{ asset.projectName }}</div>
      <div class="assets__title text-sm sm:text-xl">
        {{ asset.title }}
      </div>
      <div class="assets__price">
        <span>Current Price</span>

        <div *ngIf="asset.prices && asset.prices.length">
          <img class="balance__icon" [src]="currencyIcon" [alt]="asset.prices[0].name" />
          {{ asset.prices[0].quantity }}
        </div>
      </div>
    </div>
  </a>
  <div *ngIf="asset.properties" class="bg-secondary p-2 sm:hidden block z-5 absolute top-0 right-0"
    (click)="showProperties($event)">
    <i class="pi pi-info-circle text-2xl"></i>
  </div>
</div>