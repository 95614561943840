import { createAction, props } from '@ngrx/store';
import { Asset } from '@app/models/asset';
import { MarketplaceGetOffers$Params } from '@readygg/ng-api/fn/marketplace/marketplace-get-offers';
import { MarketplaceGetOffer$Params } from '@readygg/ng-api/fn/marketplace/marketplace-get-offer';
import { MarketplaceBuyOffer$Params } from '@readygg/ng-api/fn/marketplace/marketplace-buy-offer';
import { ReadyGGMarketplaceTransactionData } from '@readygg/ng-api';

export const loadMarketPlaceOffers = createAction(
  '[MarketPlace] Load MarketPlace Offers',
  props<{ params: MarketplaceGetOffers$Params }>(),
);

export const loadMarketPlaceOffersSuccess = createAction(
  '[MarketPlace] Load MarketPlace Offers Success',
  props<{
    assets: Asset[];
  }>(),
);

export const loadMarketPlaceOffersFailure = createAction(
  '[MarketPlace] Load MarketPlace Offers Failure',
  props<{ error: any }>(),
);

export const selectAssetById = createAction(
  '[Marketplace] Select Asset By ID',
  props<{ params: MarketplaceGetOffer$Params }>(),
);

export const selectAssetByIdSuccess = createAction(
  '[Marketplace] Select Asset By ID Success',
  props<{ asset: Asset }>(),
);

export const selectAssetByIdFailure = createAction(
  '[Marketplace] Select Asset By ID Failure',
  props<{ error: any }>(),
);

export const fetchAssetById = createAction(
  '[Marketplace] Fetch Asset By ID',
  props<{ params: MarketplaceGetOffer$Params }>(),
);

export const fetchAssetByIdSuccess = createAction(
  '[Marketplace] Fetch Asset By ID Success',
  props<{ asset: Asset }>(),
);

export const fetchAssetByIdFailure = createAction(
  '[Marketplace] Fetch Asset By ID Failure',
  props<{ error: any }>(),
);

export const buyOffer = createAction(
  '[Marketplace] Buy Offer',
  props<{ params: MarketplaceBuyOffer$Params }>(),
);


export const buyOfferSuccess = createAction(
  '[Marketplace] Buy Offer Success',
  props<{ transactionData: ReadyGGMarketplaceTransactionData }>(),
);

export const buyOfferFailure = createAction(
  '[Marketplace] Buy Offer Failure',
  props<{ error: any }>(),
);

export const startMonitoringAsset = createAction(
  '[Marketplace] Start Monitoring Asset',
  props<any>(),
);

export const invalidateCache = createAction('[Marketplace] Invalidate Cache');

export const pollingStoppedSuccess = createAction(
  '[Marketplace] Polling Stopped Success',
  props<{ asset: Asset }>(),
);

export const pollingStoppedTimeout = createAction(
  '[Marketplace] Polling Stopped Timeout',
);
