<header class="section header gap-2">

  <div class="header__left md:hidden block">
    <div class="mobile-logo">
      <img src="/assets/images/logo-play.svg" alt="" />
      <p class="subtitle-logo mt-1 text-white font-poppins text-sm opacity-80">MARKETPLACE</p>
    </div>

  </div>

  <app-game-selector></app-game-selector>

  <div class="header__right">
    <div *ngIf="tokenExpired">
      <span class="text-sm">Session expired. Please
        <span *ngIf="source" class="text-pink-500">return to the game</span>
        <a *ngIf="!source" class="text-pink-500" [href]="redirect">return to the game
        </a>
        to refresh your session.
      </span>
    </div>

    <ng-container *ngIf="!tokenExpired">
      <a *ngIf="redirect && !source" target="_blank" class="backToGame button log-in__button button__bordered px-2 py-3"
        [href]="returnPath">
        <i class="icon-android"></i>
        <i class="icon-macos"></i>
        <span class="font__s">Back to Game</span>
      </a>

      <ng-container *ngIf="user; else notLogged">
        <ng-container *ngIf="appId">
          <a routerLinkActive="active" routerLink="/buy-coins" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{ appId: appId }">
            <span class="balance rounded-btn-primary --right-image">
              <span class="label">{{
                balance
                }}</span>

              <img [ngClass]="{'hidden': !currencyIconAvailable}" [src]="currencyIcon" (load)="currencyIconLoad()"
                (error)="currencyIconMissing()" alt="Coin" />
              <span *ngIf="!currencyIconAvailable" class="img-alt">RGN</span>
            </span>
          </a>
        </ng-container>

        <app-profile [open]="isProfileOpen" (closeModal)="closeProfile()"></app-profile>

        <div class="card flex justify-content-center">
          <p-menu #menu [model]="items" [popup]="true"></p-menu>
          <span class="user-icon">
            <img [src]="user.profilePicture.source || '/images/profile.png'" alt="My Profile" />
          </span>
          <div (click)="menu.toggle($event)">
            <i class="settings-toggle mx-2 text-xl pi pi-ellipsis-v"></i>
          </div>
        </div>
      </ng-container>

      <ng-template #notLogged>
        <span class="button log-in__button button__bordered" *ngIf="!user" (click)="openAuthPopup()">
          <i class="icon-person"></i>
          <span class="font__s">Log In</span>
        </span>
      </ng-template>
    </ng-container>
  </div>
</header>