import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReadyGGUserProfileData } from '@readygg/ng-api';
import { AuthService } from '@app/core/services/auth.service';
import { ApiService } from '@app/core/services/api.service';
import { GameReview } from '@app/models/game';
import { DApp } from '@app/models/api';

@Component({
  selector: 'app-review-edit-popup',
  templateUrl: './review-edit-popup.component.html',
})
export class ReviewEditPopupComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly apiService = inject(ApiService);
  readonly dialogRef = inject(DialogRef<string>);

  form = new FormGroup({
    rating: new FormControl<number>(0, [Validators.required]),
    review: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(20),
    ]),
  });

  user: ReadyGGUserProfileData | null = null;

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      app: DApp;
      review: GameReview | undefined;
    },
  ) {
    this.authService.user$.pipe(takeUntilDestroyed()).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    if (this.data?.review) {
      this.form.setValue({
        rating: this.data.review.rating,
        review: this.data.review.content,
      });
    }
  }

  isMarked(idx: number) {
    return idx + 1 <= (this.form.get('rating') as FormControl).value;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid || !this.user) return;

    const formData = this.form.value;

    this.apiService
      .saveReview(this.user.userId, this.data.app.id, {
        id: this.data.review?.id,
        rating: formData.rating as number,
        content: formData.review as string,
      })
      .subscribe({
        next: (res) => {
          if (res) {
            this.dialogRef.close();
          } else {
            // TODO
          }
        },
        error: (err) => {
          console.log('save review err', err);
          // TODO:
        },
      });
  }
}
